import image1 from "./images/slide.png";
import image2 from "./images/slide2.png";
export default {
  slideshow: [
    {
      description: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce velit
          magna, iaculis ac nibh in, lobortis condimentum dolor. Vestibulum in
          metus lorem. Nunc consequat, erat tincidunt facilisis molestie, quam
          tellus condimentum massa, a hendrerit mi enim nec tellus. Sed
          sollicitudin erat at erat sagittis, quis condimentum sem tempus. Morbi
          in blandit eros. Nullam consequat, tellus at rutrum gravida, enim
          turpis faucibus lacus, eget vulputate leo metus lobortis ipsum. Cras
          eros mi, aliquet vitae lectus vitae, maximus imperdiet mi. Ut volutpat
          ipsum sapien, et suscipit libero eleifend id. Sed efficitur, libero ac
          lacinia volutpat, nisl eros lacinia tortor, id rhoncus leo nulla sed
          risus. Integer sapien elit, aliquam quis consequat id, bibendum et
          orci.`,
      title: "Lorem Ipsum",
      image: image1,
    },
    {
      description: `Cras arcu nisl, facilisis id sem sit amet, pharetra rutrum nunc. Mauris pulvinar mattis accumsan. Nunc vitae mi in purus ultrices vulputate. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Donec ut mi ut eros facilisis venenatis. Nulla maximus eleifend ante sit amet vulputate. Vivamus sollicitudin aliquam nisi quis molestie. Mauris vestibulum, ligula quis elementum varius, diam sapien lobortis ligula, non porttitor justo justo sit amet justo. Phasellus scelerisque turpis et velit porta, eget hendrerit dui sodales. Etiam lectus mauris, congue pellentesque facilisis eu, efficitur id purus. Mauris gravida, ipsum accumsan mollis aliquam, lorem lacus tristique leo, eu pulvinar ante magna sit amet neque. Proin pellentesque congue malesuada. Fusce id odio luctus, blandit turpis at, congue turpis.`,
      title: "Dolor sit amet",
      image: image2,
    },
  ],
};
