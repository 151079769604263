<template>
  <div class="home" v-if="$store.state.mainVisible">
    <ParallaxHome
      text1="WHAT WE PROVIDE"
      text2="Lorem Ipsum Dolor Sit Amet"
      text3="Lorem Ipsum Dolor Sit Amet. Lorem Ipsum dolor sit amet"
      :image="img"
      btn1="Lorem Ipsum"
      btn2="Get Started"
    />
  </div>

  <SlideShow v-if="$store.state.mainVisible" />
</template>

<script>
// @ is an alias to /src
import ParallaxHome from "@/components/ParallaxHome.vue";
import SlideShow from "@/components/SlideShow";
import img from "@/images/parallax.png";
export default {
  name: "Home",
  components: {
    ParallaxHome,
    SlideShow,
  },
  data() {
    return {
      img,
    };
  },
};
</script>
