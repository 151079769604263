<template>
  <div>
    <nav id="navbar">
      <div class="always-nav">
        <a class="theme">☀️</a>
        <a class="home">Home</a>
      </div>
      <div class="laptop-nav">
        <router-link to="/pricing" class="link">Pricing</router-link>
        <router-link to="/about" class="link">About Us</router-link>
        <router-link to="/contact" class="link">Contact Us</router-link>
      </div>
      <div class="phone-nav" @click="changePhone()">&equiv;</div>
    </nav>

    <div :class="`phone-links-${showphone ? 'focussed' : 'deactive'}`">
      <div class="cross-out" @click="changePhone()">&times;</div>
      <div class="stuff">
        <router-link to="/pricing" class="link">Pricing</router-link>
        <router-link to="/about" class="link">About Us</router-link>
        <router-link to="/contact" class="link">Contact Us</router-link>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "nav",
  data() {
    return {
      showphone: false,
    };
  },
  methods: {
    changePhone() {
      this.showphone = !this.showphone;
      this.$store.commit("changeVisible");
    },
  },
};
</script>
<style lang="scss">
body {
  margin: 0;
}
#navbar {
  display: flex;
  background: white;
  z-index: 9999;
  position: fixed;
  top: 0;
  width: 100%;
  box-shadow: 0px -4px 10px #807a7a;
  justify-content: space-around;
  .theme {
    font-size: 30px;
  }
  .home {
    font-size: 20px;
  }
  .laptop-nav {
    display: flex;
    justify-content: space-around;
    width: 50%;
    align-items: center;
  }
  .always-nav {
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 30%;
  }
}

.phone-links-deactive,
.phone-links-focussed {
  display: none;
}
.link {
  color: black;
  text-decoration: none;
  font-size: 20px;
}
.phone-nav {
  display: none;
}
@media (max-width: 768px) {
  .laptop-nav {
    display: none !important;
  }
  .phone-nav {
    display: block !important;
    font-size: 30px;
  }
  .phone-links-focussed {
    display: flex !important;
    position: fixed !important;
    width: 100%;
    justify-content: center;
    height: 100%;
    align-items: center;
    animation-name: come-from-top;
    animation-duration: 2s;
    animation-iteration-count: 1;
  }
  .phone-links-deactive {
    display: none;
  }
  .stuff {
    padding: 0.6rem;
    display: flex;
    flex-direction: column;
    border: 1px solid #bbbbbb;
    height: 30vh;
    justify-content: space-around;
  }
  .cross-out {
    font-size: 40px;
    transition-duration: 3s;
    cursor: pointer;
    width: max-content;
    height: max-content;
    margin-bottom: 30vh;
  }
  @keyframes come-from-top {
    from {
      padding: 10rem;
    }
    to {
      padding: 0rem;
    }
  }
  .cross-out:hover {
    color: #bbbbbb;
  }
}
</style>
