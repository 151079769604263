<template>
  <div class="main-div">
    <div class="text-div">
      <h5 class="diff-color">{{ text1 }}</h5>
      <h1>{{ text2 }}</h1>
      <h5>{{ text3 }}</h5>
      <div class="buttons">
        <button class="button-a">{{ btn1 }}</button>
        <button class="button-b">{{ btn2 }}</button>
      </div>
    </div>
    <div class="image-div">
      <img :src="image" />
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  props: ["text1", "text2", "text3", "image", "btn1", "btn2"],
  name: "Home",
};
</script>
<style lang="scss">
.text-div {
  display: flex;
  flex-direction: column;
  width: 50%;
  text-align: left;
  font-size: 140%;
  .diff-color {
    color: blue;
  }
  .buttons {
    display: flex;
    justify-content: space-between;
    width: 80%;
    button {
      font-family: Avenir, Helvetica, Arial, sans-serif;
    }
    .button-a {
      background: blue;
      color: white;
      border: 0;
      font-size: 140%;
      padding: 0.5rem;
      box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.25);
      transition-duration: 1s;
      cursor: pointer;
    }
    .button-b {
      background: white;
      color: black;
      border: 0;
      font-size: 140%;
      padding: 0.5rem;
      box-shadow: 0px 6px 4px rgba(0, 0, 0, 0.25);
      transition-duration: 1s;
      cursor: pointer;
    }
    .button-b:hover {
      box-shadow: 0px 3px 2px rgba(0, 0, 0, 0.25);
      color: #4b4141;
      background: #eeeeee;
    }
    .button-a:hover {
      box-shadow: 5px 3px 2px rgba(0, 0, 0, 0.25);
      color: #eeeeee;
      background: #000066;
    }
  }
}
.buttons {
  margin-top: 4rem;
}
.main-div {
  display: flex;
  padding: 4rem 10rem;
}
.image-div {
  img {
    width: 400px;
    margin-left: 6rem;
  }
}
@media (max-width: 768px) {
  .main-div {
    flex-direction: column;
    padding: 1rem;
  }
  .text-div {
    width: 80%;
    font-size: 100%;
    padding: 3rem;
  }
  img {
    margin-left: 0rem !important;
    margin-top: 4rem;
  }
}
</style>
