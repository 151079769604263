import { createStore } from "vuex";

export default createStore({
  state: {
    mainVisible: true,
  },
  mutations: {
    changeVisible(state) {
      state.mainVisible = !state.mainVisible;
    },
  },
  actions: {},
  modules: {},
});
