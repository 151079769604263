<template>
  <div class="main-div-read" v-if="$store.state.mainVisible">
    <div style="display: flex; margin-bottom: -50px">
      <router-link to="/">
        <Icon
          icon="akar-icons:arrow-back"
          style="color: black; font-size: 30px"
        />
      </router-link>
    </div>
    <h1>{{ slides[$route.params.slug].title }}</h1>
    <p>{{ slides[$route.params.slug].description }}</p>
    <img :src="slides[$route.params.slug].image" />
  </div>
</template>
<script>
import data from "../data";
import { Icon } from "@iconify/vue";
export default {
  name: "Read",
  data() {
    return {
      slides: data.slideshow,
    };
  },
  components: {
    Icon,
  },
};
</script>
<style lang="scss">
.main-div-read {
  margin-top: 5rem;
  img {
    width: 500px;
    margin-top: 0.5rem;
  }
}
</style>
