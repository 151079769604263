<template>
  <div class="slideshow">
    <Icon
      @click="prev()"
      icon="akar-icons:circle-chevron-left-fill"
      style="
        color: #16045e;
        font-size: 50px;
        padding: 0rem 1rem;
        cursor: pointer;
      "
    />
    <div class="center-slide">
      <h1>{{ currentSlide.title }}</h1>
      <div class="text-img">
        <p class="desc">
          {{ currentSlide.description }}
        </p>
        <router-link class="read" :to="`/read/${index}`"
          >read more...</router-link
        >
        <img :src="currentSlide.image" />
      </div>
    </div>
    <Icon
      @click="increment()"
      icon="akar-icons:circle-chevron-right-fill"
      style="
        color: #16045e;
        font-size: 50px;
        padding: 0rem 1rem;
        cursor: pointer;
      "
    />
  </div>
</template>
<script>
import { Icon } from "@iconify/vue";
import image1 from "../images/slide.png";
import slidedata from "../data";
export default {
  name: "SlideShow",
  mounted() {
    window.setInterval(this.increment, 5000);
  },
  data() {
    return {
      image1,
      slides: slidedata.slideshow,
      index: 0,
      currentSlide: slidedata.slideshow[0],
    };
  },
  components: {
    Icon,
  },
  methods: {
    increment() {
      if (this.index == this.slides.length - 1) {
        this.index = 0;
      } else {
        this.index++;
      }
      this.currentSlide = this.slides[this.index];
    },
    prev() {
      if (this.index == 0) {
        this.index++;
      } else {
        this.index--;
      }
      this.currentSlide = this.slides[this.index];
    },
  },
};
</script>
<style lang="scss">
body {
  margin-bottom: 2rem;
}
.center-slide {
  width: 80%;
  box-shadow: 0px -4px 10px rgba(0, 0, 0, 0.25);
  padding-bottom: 0.4rem;
  @media (max-width: 768px) {
    height: 43vh;
  }
}
.read {
  color: blue;
}
.slideshow {
  padding: 0.8rem;
  height: 70vh;
  display: flex;
  border: 2px solid black;
  width: 85vw;
  margin: auto;
  .text-img {
    display: flex;
    @media (max-width: 768px) {
      flex-direction: column;
      .desc {
        display: none;
      }
      img {
        width: 200px !important;
      }
      padding: 3rem;
      height: 15vh;
    }
    .desc {
      width: 43%;
    }
    img {
      width: 500px;
    }
    align-items: center;
    justify-content: space-around;
    @media (max-width: 768px) {
      justify-content: center !important;
      img {
        margin-top: 1rem;
      }
    }
  }
  align-items: center;

  .read {
    display: none;
  }
  @media (max-width: 768px) {
    height: 50vh !important;
    .read {
      display: block;
    }
  }
}
</style>
